var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-card',{staticClass:"pt-1 pb-1",staticStyle:{"max-width":"1200px"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card-text',[_c('h4',[_vm._v(_vm._s(_vm.$t('General Information')))])]),_c('b-row',{staticClass:"mt-2 mb-2 align-items-center  pl-lg-5 pr-lg-5 pl-md-2 pr-md-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mr-lg-3",attrs:{"label":"Prefix","label-for":"mc-prefix","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Prefix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-prefix","placeholder":"Prefix"},model:{value:(_vm.form.prefix),callback:function ($$v) {_vm.$set(_vm.form, "prefix", $$v)},expression:"form.prefix"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"ml-lg-3",attrs:{"label":"Username","label-for":"mc-username","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-username","placeholder":"Username"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mr-lg-3",attrs:{"label":"Password","label-for":"mc-password","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|min:8|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-password","placeholder":"Password","state":errors.length > 0 ? false:null},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"ml-lg-3",attrs:{"label":"Confirm Password","label-for":"mc-confirm-password","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-confirm-password","state":errors.length > 0 ? false:null,"placeholder":"Confirm Password"},model:{value:(_vm.form.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirm", $$v)},expression:"form.passwordConfirm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mr-lg-3",attrs:{"label":"Nickname","label-for":"mc-nickname","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nickname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-nickname","placeholder":"Nickname"},model:{value:(_vm.form.nickName),callback:function ($$v) {_vm.$set(_vm.form, "nickName", $$v)},expression:"form.nickName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"ml-lg-3",attrs:{"label":"Phone","label-for":"mc-phone","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-phone","placeholder":"Phone","minlength":"10","maxlength":"10","state":errors.length > 0 ? false:null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mr-lg-3",attrs:{"label":"Email","label-for":"mc-email","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-email","placeholder":"Email","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.$can('manage', 'all'))?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"ml-lg-3",attrs:{"label":"Can Create Agent","label-for":"mc-phone","label-cols-md":"4"}},[_c('div',{staticClass:"inline-spacing"},[_c('b-form-radio',{staticClass:"custom-control-primary",attrs:{"value":true},model:{value:(_vm.form.canCreateAgent),callback:function ($$v) {_vm.$set(_vm.form, "canCreateAgent", $$v)},expression:"form.canCreateAgent"}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")]),_c('b-form-radio',{staticClass:"custom-control-secondary ml-1",attrs:{"value":false},model:{value:(_vm.form.canCreateAgent),callback:function ($$v) {_vm.$set(_vm.form, "canCreateAgent", $$v)},expression:"form.canCreateAgent"}},[_vm._v(" "+_vm._s(_vm.$t('no'))+" ")])],1)])],1):_vm._e()],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-card-text',[_c('h4',[_vm._v(_vm._s(_vm.$t('Credit Setting')))])]),_c('div',{staticClass:"pl-lg-5 pl-md-2"},[_c('b-form-group',{staticClass:"mr-lg-3",attrs:{"label":_vm.$t('Our PT(%)'),"label-for":"our-pt","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"mc-our-pt","placeholder":_vm.$t('Our PT(%)'),"disabled":""},model:{value:(_vm.ourPercent),callback:function ($$v) {_vm.ourPercent=$$v},expression:"ourPercent"}})],1)],1),_c('div',{staticClass:"pl-lg-5 pl-md-2"},[_c('b-form-group',{staticClass:"mr-lg-3",attrs:{"label":_vm.$t('Given PT(%)'),"label-for":"mc-pt","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Given PT(%)'),"rules":"required|decimal|min_value:1|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-pt","type":"number","placeholder":_vm.$t('Given PT(%)')},on:{"input":_vm.finishInputPercent},model:{value:(_vm.form.percent),callback:function ($$v) {_vm.$set(_vm.form, "percent", $$v)},expression:"form.percent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-card-text',[_c('h4',[_vm._v(_vm._s(_vm.$t('Etc Setting')))])]),_c('div',{staticClass:"pr-lg-5 pr-md-2"},[_c('b-form-group',{staticClass:"ml-lg-3",attrs:{"label":"Website URL","label-for":"mc-website-url","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Website URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-website-url","placeholder":"Website URL"},model:{value:(_vm.form.websiteUrl),callback:function ($$v) {_vm.$set(_vm.form, "websiteUrl", $$v)},expression:"form.websiteUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"pr-lg-5 pr-md-2"},[_c('b-form-group',{staticClass:"ml-lg-3",attrs:{"label":"CallBack URL","label-for":"mc-callback-url","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"CallBack URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-callback-url","placeholder":"Callback URL"},model:{value:(_vm.form.callbackUrl),callback:function ($$v) {_vm.$set(_vm.form, "callbackUrl", $$v)},expression:"form.callbackUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"pr-lg-5 pr-md-2"},[_c('div',{staticClass:"form-row form-group ml-lg-3",attrs:{"role":"group"}},[_c('div',{staticClass:"col-md-4 col-form-label"},[_vm._v("IP Whitelist "),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon ",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.addItem()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1),_c('div',{ref:"whitelistForm",staticClass:"col mt-25 repeater-form",style:({height: _vm.trHeight})},_vm._l((_vm.form.ipWhiteList),function(v,i){return _c('div',{key:i,ref:"row",refInFor:true},[_c('validation-provider',{attrs:{"name":"IP Whitelist","rules":"regex:^\\d+\\.\\d+\\.\\d+\\.\\d+$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"IP Address"},model:{value:(_vm.form.ipWhiteList[i]),callback:function ($$v) {_vm.$set(_vm.form.ipWhiteList, i, $$v)},expression:"form.ipWhiteList[i]"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.removeItem(i)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}),0)])])],1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmitForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }